<template>
    <div class="merchatlogin">
        <div class="merchatlogin-wrap">
            <div class="merchatlogin-wrap-top">
                <img src="@/assets/sj_bj.png" alt />
            </div>
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px"
                class="merchatlogin-wrap-login demo-ruleForm">
                <el-form-item prop="account">
                    <el-input placeholder="请输入账号" type="text" v-model="ruleForm.account" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="Pass">
                    <el-input placeholder="请输入登录密码" type="password" v-model="ruleForm.Pass" autocomplete="off">
                    </el-input>
                </el-form-item>
                <p class="login" @click="checkData">登录</p>
                <!-- <p class="password">
					<router-link to="/phonelogin">忘记密码？手机号登录</router-link>
                </p>-->
            </el-form>
            <!-- 登录方式 -->
            <login-way :loginFrom="1"></login-way>
        </div>
    </div>
</template>

<script>
    import {
        MerchatLogin
    } from "@/http/api";
    // import {Message} from "element-ui";
    import LoginWay from "./LoginChildren/LoginWay";
    export default {
        components: {
            LoginWay
        },
        data() {
            var validateAccount = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入账号"));
                } else {
                    callback();
                }
            };
            var validatePass = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("请输入密码"));
                } else {
                    callback();
                }
            };
            return {
                ruleForm: {
                    account: "",
                    Pass: ""
                },
                rules: {
                    account: [{
                        validator: validateAccount,
                        trigger: "blur"
                    }],
                    Pass: [{
                        validator: validatePass,
                        trigger: "blur"
                    }]
                }
            };
        },
        mounted() {
            if (this.$LStorage.getItem("shopUserInfo")) {
                this.$router.replace({
                    path: "/merchantwork"
                });
            }
        },
        methods: {
            checkData() {
                this.$refs["ruleForm"].validate(valid => {
                    if (valid) {
                        this.MerchatLogin();
                    }
                });
            },
            async MerchatLogin() {
                const res = await MerchatLogin({
                    data: {
                        user_account: this.ruleForm.account,
                        user_pass: this.ruleForm.Pass
                    }
                });
                if (res.code == 200) {
                    this.$LStorage.setItem("shopUserInfo", res.data);
                    let that = this;
                    this.$toast.success({message:'登录成功', onClose:function(){
                        that.$router.replace({
                            path: "/merchantwork"
                        });
                    }});
                } else {
                    this.$toast(res.msgs);
                }
            }
        }
    };
</script>

<style lang="scss">
    .merchatlogin {
        width: 100%;
        min-height: 100vh;
        background: #f6f6f6;

        .my-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0.3rem;
            box-sizing: border-box;
            font-size: 0.38rem;
            background: #f4f4f4;
        }

        .merchatlogin-wrap {
            img {
                width: 100%;
                height: 4.54rem;
            }
        }

        .merchatlogin-wrap-login {
            width: 5.6rem;
            margin: 0 auto;
            background: #fff;
            border-radius: 0.07rem;
            padding: 0.39rem 0.45rem 0.45rem 0.52rem;
            box-sizing: border-box;
            margin-top: 0.45rem;

            input {
                width: 4.55rem;
                height: 0.73rem;
                background: #f5f5f5;
                margin-bottom: 0.26rem;
                font-size: 0.27rem;
            }

            .login {
                width: 4.55rem;
                height: 0.73rem;
                border-radius: 0.04rem;
                font-size: 0.26rem;
                color: #fff;
                background: #e14e2a;
                margin-top: 0.5rem;
                text-align: center;
                line-height: 0.73rem;
            }

            .password {
                font-size: 0.23rem;
                display: flex;
                justify-content: flex-end;
                color: #e14e2a;
                margin-top: 0.36rem;
            }
        }

        .el-form-item__content {
            margin-left: 0 !important;
            margin-top: -0.1rem !important;
        }
    }
</style>
